/**
 * 定义table的展开图表
*/

import {h} from 'vue'
// 点击展开图标
interface ExpandFn {
    (record: any, event: Element): void;
}
interface ExpandProps {
    expandable: boolean;
    expanded: boolean;
    needIndentSpaced: boolean;
    onExpand: ExpandFn;
    prefixCls: string;
    record: any;
}
// 自定义展开icon图标
const clickExpand = (props: ExpandProps,e: Element) => {
    props.onExpand(props.record, e)
} 
// 自定义展开icon
const expandIcon = (props: ExpandProps) => {
    if (props.record.children && props.record.children.length > 0 ) {
        if (props.expanded) {
            //有数据-展开时候图标
            return h("i", {
                class: "font_family icon-xiangxia m-r-5 cursor-p",
                onClick: (e: Element) => {
                    clickExpand(props,e)
                },
            });
        } else {
            //有数据-未展开时候图标
            return h("i", {
                class: "font_family icon-xiangyou m-r-5 cursor-p",
                onClick: (e: Element) => {
                    clickExpand(props,e)
                },
            });
        }
        } else {
            //无数据-图标
            return h("i", {
                class: "m-r-5",
                style: { width: '16px', height: '1px', display: 'inline-block'}
            });
    }
};
 
export default expandIcon
