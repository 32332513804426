
import { defineComponent, ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import {
  getTypeModelYearList,
  //   getFileList,
  //   getCustomerType,
  //   getBrandList,
  //   getCalculatorMaintain,
  //   getSelectVehicle,
  //   getAllBu,
  //   getBrandByBu,
} from "@/API/finance";
import { FileResponse, FileInfo } from "@/API/types";
import { TreeSelect } from "ant-design-vue";
import blobDownload from "@/utils/blobDownload";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
interface TreeDataItem {
  value: string;
  key: string;
  title?: string;
  disabled?: boolean;
  children?: TreeDataItem[];
}
const qmOptions: TreeDataItem[] = [
  {
    title: "Q1",
    value: "Q1",
    key: "Q1",
    children: [
      {
        title: "01",
        value: "01",
        key: "01",
      },
      {
        title: "02",
        value: "02",
        key: "02",
      },
      {
        title: "03",
        value: "03",
        key: "03",
      },
    ],
  },
  {
    title: "Q2",
    value: "Q2",
    key: "Q2",
    children: [
      {
        title: "04",
        value: "04",
        key: "04",
      },
      {
        title: "05",
        value: "05",
        key: "05",
      },
      {
        title: "06",
        value: "06",
        key: "06",
      },
    ],
  },
  {
    title: "Q3",
    value: "Q3",
    key: "Q3",
    children: [
      {
        title: "07",
        value: "07",
        key: "07",
      },
      {
        title: "08",
        value: "08",
        key: "08",
      },
      {
        title: "09",
        value: "09",
        key: "09",
      },
    ],
  },
  {
    title: "Q4",
    value: "Q4",
    key: "Q4",
    children: [
      {
        title: "10",
        value: "10",
        key: "10",
      },
      {
        title: "11",
        value: "11",
        key: "11",
      },
      {
        title: "12",
        value: "12",
        key: "12",
      },
    ],
  },
];
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    baseURL: String,
    offerType: String,
    bu: String,
    brand: String,
    customerType: String,
    offer: String,
  },
  emits: ["close-supplementary-calculator", "export-supplementary-calculator"],
  setup(props, { emit }) {
    const activeKey = ref("export");

    const vehicleSelectList = reactive({
      typeClass: [],
      typeClassMapperModelYear: {},
      modeYearMapperModel: {},
      modeYearMapperGroup: {},
    });

    const getSelectList = (param = {}) => {
      const params = {
        offerType: props.offerType,
        bu: props.bu,
        brand: props.brand,
        customerType: props.customerType,
        offer: props.offer,
        ...param,
      };
      return getTypeModelYearList(params).then((res: any) => {
        Object.assign(vehicleSelectList, res);
        return res;
      });
    };

    const valueTypeClass = ref([]);
    const classSelect = ref([]);
    watch(
      () => props.visible,
      (params: boolean) => {
        if (params) {
          getSelectList().then((res: any) => {
            classSelect.value = res.typeClass;
          });
        }
      }
    );

    const handleBlurTypeClass = () => {
      modelSelect.value = [];
      modelYearSelect.value = [];
      valueModelYear.value = [];
      getSelectList({ typeClass: valueTypeClass.value }).then(() => {
        const tempModelYearList: any[] = [];
        valueTypeClass.value.forEach((item) => {
          tempModelYearList.push(
            ...(vehicleSelectList.typeClassMapperModelYear[item] as [])
          );
        });
        modelYearSelect.value = Array.from(new Set(tempModelYearList));
      });
      modelYearSelect.value = Array.from(new Set(modelYearSelect.value));
    };

    const valueModelYear = ref([]);
    const modelYearSelect = ref<any[]>([]);
    const handleBlurModelYear = () => {
      modelSelect.value = [];
      valueModel.value = [];
      valueNstGroupName.value = [];
      nstGroupNameList.value = [];
      getSelectList({
        typeClass: valueTypeClass.value,
        modelYear: valueModelYear.value,
      }).then(() => {
        const tempModelList: any[] = [];
        const tempNstList: any[] = [];
        valueModelYear.value.forEach((item) => {
          if (item in vehicleSelectList.modeYearMapperModel) {
            tempModelList.push(
              ...(vehicleSelectList.modeYearMapperModel[item] as [])
            );
          }
          if (item in vehicleSelectList.modeYearMapperGroup) {
            tempNstList.push(
              ...(vehicleSelectList.modeYearMapperGroup[item] as [])
            );
          }
        });
        modelSelect.value = Array.from(new Set(tempModelList));
        nstGroupNameList.value = Array.from(new Set(tempNstList));
      });
    };

    const valueModel = ref([]);
    const modelSelect = ref<any[]>([]);

    const exportActiveKey = ref("1");
    const valueNstGroupName = ref<any[]>([]);
    const nstGroupNameList = ref<any[]>([]);
    const exportTabChange = () => {
      valueModel.value = [];
      valueNstGroupName.value = [];
    };

    const year = ref<string>(moment().add("month", 1).format("YYYY"));
    const openYear = ref<boolean>(false);
    const handleOpenChange = (status: any): void => {
      if (status) {
        openYear.value = true;
      }
    };
    const handlePanelChange = (value: string): void => {
      const time = moment(value).format("YYYY");
      year.value = time as string;
      openYear.value = false;
    };

    // 季度 月份
    const getDefaultQm = (): string => {
      return "Q" + moment().add("month", 1).quarter();
    };
    const qm = ref<string[]>([getDefaultQm()]);

    const store = useStore();
    const fileList = ref([]);

    const headers = {
      Authorization: store.state.Authorization,
    };

    const uploadMsg = ref("");
    const detailMsg = ref("");

    const isUploadDone = ref(true);
    const isUploadType = ref(true);

    const visibleWait = ref(false);
    const visibleDetail = ref(false);
    // 上传文件
    const uploadChange = (info: FileInfo) => {
      if (info.file.status === "done") {
        visibleWait.value = true;
        if (
          !(info.file.response as FileResponse).message.includes("unsuccessful")
        ) {
          isUploadDone.value = true;
          uploadMsg.value = (info.file.response as any).data.fileMessage;
          isUploadType.value = true;
        } else {
          if (!(info.file.response as FileResponse).data) {
            isUploadType.value = false;
          } else {
            isUploadType.value = true;
          }
          isUploadDone.value = false;
          detailMsg.value = (info.file.response as FileResponse).data;
          uploadMsg.value = (info.file.response as FileResponse).message;
        }
      } else if (info.file.status === "error") {
        visibleWait.value = true;
        isUploadDone.value = false;
        uploadMsg.value =
          "Data validation unsuccessful, please check and re-upload calculator.";
        detailMsg.value = (info.file.response as FileResponse).message;
      }
    };

    const onClose = () => {
      visibleWait.value = false;
      if (!isUploadDone.value) fileList.value.pop();
    };

    const onDetail = () => {
      visibleDetail.value = true;
    };

    // 导出
    const genExportParams = () => {
      const quarter = {
        Q1: ["01", "02", "03"],
        Q2: ["04", "05", "06"],
        Q3: ["07", "08", "09"],
        Q4: ["10", "11", "12"],
      };
      const quarterTomonth: string[] = [];
      qm.value.forEach((item) => {
        if (quarter[item]) {
          quarterTomonth.push(...quarter[item]);
        } else {
          quarterTomonth.push(item);
        }
      });

      return {
        typeClass: valueTypeClass.value.join(),
        modelYear: valueModelYear.value.join(),
        model: valueModel.value.join(),
        nstGroupName: valueNstGroupName.value.join(","),
        year: year.value ? year.value : moment().add("month", 1).format("YYYY"),
        month: quarterTomonth.join(),
        brand: props.brand,
        customerType: props.customerType,
        offerType: props.offerType,
        offer: props.offer,
        bu: props.bu,
      };
    };
    const onExport = () => {
      const config = {
        url: `/pgapi/prog/setting/fa/exportSupplementaryCalculator`,
        method: "post",
        params: genExportParams(),
      };
      blobDownload(config);
      close();
    };

    const close = () => {
      emit("close-supplementary-calculator");
      valueTypeClass.value = [];
      valueModel.value = [];
      modelSelect.value = [];
      valueModelYear.value = [];
      modelYearSelect.value = [];
      classSelect.value = [];
      valueNstGroupName.value = [];
      nstGroupNameList.value = [];
      qm.value = [getDefaultQm()];
    };

    return {
      close,
      activeKey,
      valueTypeClass,
      classSelect,
      handleBlurTypeClass,
      valueModelYear,
      modelYearSelect,
      handleBlurModelYear,
      valueModel,
      modelSelect,
      exportActiveKey,
      valueNstGroupName,
      nstGroupNameList,
      year,
      openYear,
      handleOpenChange,
      handlePanelChange,
      qm,
      qmOptions,
      SHOW_PARENT,
      exportTabChange,
      headers,
      fileList,
      onExport,
      uploadChange,
      uploadMsg,
      detailMsg,
      visibleWait,
      visibleDetail,
      onClose,
      onDetail,
      isUploadDone,
      isUploadType,
    };
  },
});
