
import { defineComponent, PropType } from 'vue'
import { MaintainCellProps, } from '../types'
import { ColmunsProps, } from '@/API/types'
import expandIcon from '@/hooks/useExpandIcon'

export default defineComponent({
    props: {
        dataSource: {
            default: () => [],
            type: Array as PropType<MaintainCellProps[]>
        },
        columns: {
            default: () => [],
            type: Array as PropType<ColmunsProps[]>
        },
        valueBrand: {
            default: '',
            type: String
        },
        tableHeight: {
            default: 0,
            type: Number
        }
    
    },
    name: '',
    setup() {
        // const {dataSource ,columns,  valueBrand} = toRefs(props)
         return {
             expandIcon,
            //  VTComponents,

         }

    }
 });
