import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font_family icon-guanbi color-g"
}
const _hoisted_2 = {
  key: 1,
  class: "font_family icon-CloudUpload color-l"
}
const _hoisted_3 = {
  key: 2,
  class: "font_family icon-Uploadsucceeded color-green"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_component_a_table, {
      columns: _ctx.columns,
      bordered: "",
      key: new Date(),
      defaultExpandAllRows: _ctx.valueBrand !== 'MB',
      pagination: false,
      expandIcon: _ctx.expandIcon,
      "data-source": _ctx.dataSource,
      scroll: { x: 1400, y: _ctx.tableHeight - 130},
      rowKey: (data, index) => index,
      class: "clearfix self-table-fix self-table-fix-group",
      rowClassName: (record, index) => (index % 2 === 1 ? 'table-striped' : null)
    }, {
      active: _withCtx(({text}) => [
        (text === '')
          ? (_openBlock(), _createElementBlock("i", _hoisted_1))
          : (text === 'F')
            ? (_openBlock(), _createElementBlock("i", _hoisted_2))
            : (text === 'T')
              ? (_openBlock(), _createElementBlock("i", _hoisted_3))
              : _createCommentVNode("", true)
      ]),
      model: _withCtx(({text}) => [
        _createTextVNode(_toDisplayString(text), 1)
      ]),
      fixedRight: _withCtx(({ text }) => [
        _createElementVNode("i", {
          class: _normalizeClass([text === 'T' ? 'color-l': 'color-g', "font_family icon-qizi"])
        }, null, 2)
      ]),
      _: 1
    }, 8, ["columns", "defaultExpandAllRows", "expandIcon", "data-source", "scroll", "rowKey", "rowClassName"]))
  ]))
}